* {
  margin: 0;
  padding: 0;
  font-family: 'SenticosansDTCond Thin';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --size-xs: 12px;
  --size-sm: 14px;
  --size-md: 16px;
  --size-lg: 18px;
  --size-xl: 20px;
  --size-2xl: 24px;
  --theme: #e9f5db;
  --form-submit: #85cdea;
  --theme-button: #97a97c;
  --theme-active-menu: #cfe1b9;
  --theme-hover: #b5c99a;
  --login-hover: #d1d4d6;
  --white: #ffff;
  --form-disabled: #e3e3e3;
  --required: #ff0000;
}
/* 
::-webkit-scrollbar {
  display:contents;
} */

.notShowScrollBar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(92, 99, 128, 0.7);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(92, 99, 128, 0.9);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Login form css */
.login-container {
  max-height: 1000px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: var(--white);
  box-sizing: content-box !important;
  object-fit: cover;
}

.password-setting {
  max-width: 800px;
  max-height: 700px;
  width: 100%;
  height: auto;
  margin-top: 10%;
  border-radius: var(--size-lg);
  background: var(--white);
  box-sizing: content-box !important;
  padding: 20px;
}

.ql-container {
  background-color: var(--white);
  height: 100px !important;
}

.login-img {
  cursor: pointer;
  background-repeat: no-repeat;
  border-top-left-radius: var(--size-lg);
  border-bottom-left-radius: var(--size-lg);
}

.fixedSize {
  max-height: 90vh;
  height: 100%;
  overflow: hidden;
}

/* my project bids css */
.bid-details-form {
  max-height: '57vh';
  height: "100%";
  overflow-y: 'scroll';
}

.bid-address-information {
  max-height: '68vh';
  overflow-y: scroll;
  overflow-x: 'hidden';
  background-color: '#f6f7f7';
}

.qAndA-accordion {
  max-height: '68vh';
  overflow-y: hidden;
  overflow-x: hidden;
}

.bid-info {
  max-height: '68vh';
  overflow-y: 'hidden';
  overflow-x: 'hidden';
}

.bid-info-content {
  max-height: '45vh';
  overflow-y: 'scroll';
  overflow-x: 'hidden';
  background-color: '#f6f7f7';
}

/* Manage user css*/


/* .select ~label, */

.btn {
  text-transform: capitalize !important;
}

.main-btn {
  background-color: #19255c !important;
  color: #f6f7f7 !important;
  font-family: 'SenticosansDTCond Thin' !important;
}

.btn:hover {
  opacity: 1;
  color: white;
}

.btn:disabled {
  opacity: 0.3 !important;
}

.pointer {
  cursor: pointer;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--mdb-modal-header-padding);
  border-bottom: var(--mdb-modal-header-border-width) solid var(--mdb-modal-header-border-color);
  border-top-left-radius: var(--mdb-modal-inner-border-radius);
  border-top-right-radius: var(--mdb-modal-inner-border-radius);
}

.modal-body {
  padding: 20px;
}

.modal-body p {
  margin: 0;
}

/* CSS */
.otp-container {
  display: flex;
}

.otp-container-focused {
  display: flex;
  border: 2px solid blue;
}

.otp-input {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.theme-color {
  color: #19255c
}

.theme-bg {
  background: #19255c
}

.theme-check {
  color: #19255c !important
}

.btn-bg {
  background: #19255c !important;
  border: none !important;
  color: white !important;
  font-size: 14px !important;
}

.btn:hover {
  opacity: 1;
  color: white;
}

.btn-bg:hover {
  background-color: #19255c !important;
}

.btn:disabled {
  opacity: 0.3 !important;
}

ul {
  list-style: none;
}

.size-xs {
  font-size: var(--size-xs) !important;
}

.size-sm {
  /* font-size: var(--size-sm) !important; */
  font-size: clamp(var(--size-xs) , 2vw, var(--size-sm) ) !important;

}

.size-md {
  font-size: var(--size-md) !important;
}

.size-lg {
  font-size: var(--size-lg) !important;
  font-size: clamp(var(--size-sm) , 2vw, var(--size-lg) ) !important;
}

.size-xl {
  font-size: var(--size-xl) !important;
}

.size-2xl {
  font-size: var(--size-2xl) !important;
}

.fs-55 {
  /* font-size: 55px !important; */
  /* font-size: clamp(100px, calc(30% / 2rem + 10px), 900px); */
  letter-spacing: 2px;
  font-size: clamp(1.8rem, 2.5vw, 2.8rem) !important;


}

.glow-text {
  color: rgba(102, 140, 255, 0.4);
  text-shadow: 0 0 15px rgba(102, 140, 255, 0.6), 0 0 0 rgba(102, 140, 255, 0.4);
}

.text-box {
  flex-grow: 1;
  max-width: calc(100% - 50px);
  max-height: 60px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px;
  margin-right: 5px;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.receiver {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Changed alignment */
}

.sender {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Changed alignment */
}

.receiver-message {
  background: #b3d4fc;
  padding: 3px;
  border-radius: 10px;
  margin-right: 5px;
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  margin-bottom: 5px;
}

.sender-message {
  background: #f9cb9c;
  padding: 3px;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  margin-bottom: 5px;
}

.custom-breadcrumb {
  padding: 0.5rem 1rem;
  /* Example padding */
}

.p-menuitem-text {
  color: rgb(0, 136, 255) !important;

}

.p-menuitem-link {
  text-decoration: none;
}

/* .MuiBadge-badge {
  opacity: 0.5 !important;
} */

.MuiTreeItem-iconContainer {
  background-color: #5c6380 !important;
  border-radius: 50%;
  color: #ffff;
}

.MuiTreeItem-groupTransition {
  margin-left: 15px !important;
  padding-left: 18px !important;
  border-left-style: dotted;
  border-left: solid rgb(87, 88, 87);
}

/* .MuiFormControlLabel-label {
  font-size: var(--size-xs) !important;
  text-transform: capitalize !important;
} */

/* Split Screen Movable bar CSS */

.split-bar {
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.2;
  z-index: 999;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  cursor: col-resize;
}

/* Side Bar Css */
.side-nav-button {
  font-size: var(--size-xl);
  cursor: pointer;
}

.side-nav-button:hover {
  border-radius: 30%;
}

.side-nav-centent {
  width: 180px !important;
  margin: 10px 0px 0px 0px;
}

.nav-list {
  list-style: none;
  height: 100%;
  padding: 0%;
  margin-bottom: 0%;
}

.nav-list-items {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
  width: auto;
  text-align: center;
  line-height: 25px;
}

.ps-sidebar-container {
  background-color: transparent !important;
}

.side-bar-minimize {
  position: fixed;
  background: #e9f5db;
  width: 60px;
  height: calc(100vh - 4rem);
  white-space: nowrap;
  transition: width 0.5s ease-out;
}

.side-bar-maximize {
  white-space: nowrap;
  transition: width 0.5s ease-out;
  width: 250px;
}

/* Drag And Drop ,Upload File  */

.dropzone {
  text-align: center;
  padding: 15px;
  border: 3px dashed #eeeeee;
  /* background-color: #fafafa; */
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}

/* Ready Only Quill CSS */

.read-only-editor .ql-container,
.read-only-editor .ql-toolbar {
  background-color: #eee;
}

@media (max-height: 900px) {

  /* Your CSS styles here */
  .fixedSize {
    max-height: 110vh;
    height: 100%;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .password-setting {
    max-width: 90%;
    max-height: 90%;
    padding: 10px;
  }
}

/* For medium screens */
@media (min-width: 601px) and (max-width: 900px) {
  .password-setting {
    max-width: 70%;
    max-height: 80%;
  }
}
