app-header {
    position: fixed;
    width: 100%;
    top: 0px;
    height: 4rem;
    z-index: 0 !important;
    overflow: hidden;
  }
  
  main {
    position: fixed;
    width: 100%;
    top: 4rem;
  }
  
  .display-area-maximize{
    position: absolute;
    left: 250px;
    width: calc(100% - 250px);
    transition: left 0.5s ease-out, width 0.5s ease-out;
  }
  
  .display-area-minimize{
    left: 60px;
    width: calc(100% - 60px);
  }
  
  .header-area {
    height: 3rem;
    text-align: center;
    padding: 10px;
  }
  